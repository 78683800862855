import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { useState } from "react";
import _ from "lodash";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import {
  alpha,
  experimentalStyled as styled,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { useList, useButton } from "@motor-js/engine";
import ReactGA from "react-ga";

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&.isActiveRoot": {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "&:before": {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      position: "absolute",
      backgroundColor: theme.palette.primary.main,
    },
  },
  "&.isActiveSub": {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    "& .subIcon:before": {
      transform: "scale(2)",
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const SubIconStyle = styled("span")(({ theme }) => ({
  width: 22,
  height: 28,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:before": {
    width: 4,
    height: 4,
    content: "''",
    display: "block",
    borderRadius: "50%",
    backgroundColor: theme.palette.text.disabled,
    transition: theme.transitions.create("transform"),
  },
}));

const useStyles = makeStyles((theme) => ({
  maxHeight: {
    maxHeight: "300px",
    overflowY: "scroll",
  },
}));

QlikDropDown.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  open: PropTypes.bool,
  singleSelect: PropTypes.bool,
};

export default function QlikDropDown({
  title,
  icon,
  singleSelect,
  open = false,
}) {
  const [show, setShow] = useState(open);

  const handleShow = () => {
    setShow((show) => !show);
  };

  const { selectValues } = useButton();

  const dimension = [title];

  const { listData, select } = useList({
    dimension,
  });

  const classes = useStyles();

  const handleSelectEvent = (item, title) => {
    ReactGA.event({
      category: "Filter",
      action: "Filter selected",
      label: `${title} | ${item.text}`,
    });
  };

  const handleSelectSingle = (item, title) => {
    selectValues([item.text], title, false);
    handleSelectEvent(item, title);
  };

  const handleSelect = (item, title) => {
    select([item.key]);
    handleSelectEvent(item, title);
  };

  return (
    <>
      <ListItemStyle
        button
        disableGutters
        onClick={handleShow}
        className={open ? "isActiveRoot" : ""}
      >
        <ListItemIcon></ListItemIcon>
        <ListItemText disableTypography primary={title} />
        <Box
          component={Icon}
          icon={show ? arrowIosDownwardFill : arrowIosForwardFill}
          sx={{ width: 16, height: 16, ml: 1 }}
        />
      </ListItemStyle>

      <Collapse in={show}>
        <List className={classes.maxHeight}>
          {listData === null
            ? null
            : _.orderBy(listData, ["text"], ["asc"]).map((item, index) => (
                <ListItemStyle
                  button
                  disableGutters
                  key={item.key}
                  onClick={() =>
                    singleSelect
                      ? handleSelectSingle(item, title)
                      : handleSelect(item, title)
                  }
                  // selected={item.state === "S" ? true : false}
                  className={item.state === "S" ? "isActiveSub" : ""}
                >
                  <ListItemIcon>
                    <SubIconStyle className="subIcon" />
                    <ListItemText disableTypography primary={item.text} />
                  </ListItemIcon>
                </ListItemStyle>
              ))}
        </List>
      </Collapse>
    </>
  );
}
