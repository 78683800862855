// material
import { Chip } from "@material-ui/core";
// utils
// motor JS

import { useSelections, useButton } from "@motor-js/engine";

export default function QlikSelections() {
  const { selections } = useSelections();
  const { selectValues } = useButton();

  return selections === null
    ? null
    : selections.map((item, index) => (
        <Chip
          key={index}
          label={item.qSelected}
          onDelete={
            ![
              "Value",
              "Volume",
              "Last Year",
              "Last Last Year",
              "Budget",
            ].includes(item.qSelected)
              ? () =>
                  selectValues(
                    [item.qSelected],
                    item.qField,
                    item.qSelectedCount > 1 ? false : true
                  )
              : false
          }
          onClick={() =>
            selectValues(
              [item.qSelected],
              item.qField,
              item.qSelectedCount > 1 ? false : true
            )
          }
        />
      ));
}
